/**
* @log.utils
* Class for remote logging
*
*/

class logUtils {

	constructor() {

		this._logHandlerPath = "theme/badge-app/src/php/log.php";

	}

	/* Log to file */
	log(message, type) {

		var data = {
			"message": message,
			"level": type
		}

		$.ajax({
			url: this._logHandlerPath,
			async: true,
			data: data,
			method: "POST",
			dataType: "json",
			complete: function(response) {
				console.log(data);
			}
		});

		return null;
	}

}

module.exports = logUtils;
