
/**
* index.js
*
* App entry point. Trigger for loading function by page routing
*/

// Globals
var globals = require('./globals.js');

// Modules
window.jQuery = $ = require('jquery');
var Bootstrap = require('bootstrap/dist/js/bootstrap');

// Pages
var ReportPage = require('./reports/reports.page.js');
var LogUtils = require('./utils/utils.log.class.js');
var SubscriptionForm = require('./subscriptionForm/subscriptionForm.js');

var logUtility = new LogUtils();

// Try to inject triggers directly n referred page for avoiding readin globals
jQuery(document).ready(function ($) {

	console.log('tesseramento ready to go');
	// Sets report page
	if ($('#js-trigger-page-reports').length) {
		var pageReport = {};
		pageReport = new ReportPage();
	};

	if ($('#js-trigger-form').length) {
		// Prepare form
		var pageForm = {};
		var pageForm = new SubscriptionForm(global.FORM_ID);
	};
});
