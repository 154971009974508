"use strict"; 

/**
 * boltLabels
 *
 * Port to bolt.cm Labels extension
 * 
 */
 
 // Todo switch laguage


class boltLabels {
	
	_labelsDB = {}
		
	constructor() {
		
		this._labelsDB = this._fetchLabels();
						
	}
	
	// Alias for _matchLabels().
	l(id){
		var label;
			
		label =this. _matchLabel(id);
		
		return label
	}
	
	// Check if id exists in DB otherwise print a placeholder.
	_matchLabel(id) {
		var label;
		var lang = "it";
		
		if (this._labelsDB[id]) {
			label = this._labelsDB[id][lang];
		} else {
			label = `#${id}#`;
		}
		
		return label
	}
	
	// Gets labelsDB.
	_fetchLabels(){
		var jsonLabels;
		
		$.ajax({
			url: global.LABELS_HANDLER_PATH,
			async: false,
			dataType: "json",
			complete: function(response) {
				jsonLabels = response.responseJSON;
			}
		});
		
		return jsonLabels;
	}
}

module.exports = boltLabels;