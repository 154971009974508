"use strict"; 


/**
 * ui.frtendHelper.js 
 *
 * Class contains useful functions for fronted UI
 * 
 */


// Modules
var tmplateEngine = require('../templateEngine/templateEngine.js');



class frtendHelper {
	
	// Hardcoded, is it the fastest solution?
	userMessagesContainerID = global.USER_MESSAGE_CONTAINER_ID;
	userMessagesTextID = global.USER_MESSAGE_TEXT_ID;
	
	constructor() {
					
	}

	
	showBootstrapMessage(template, bootstrapClass, jsonData) {
		
		var xmlDoc = tmplateEngine.fetchTemplate(template, jsonData);	
	
		var message = $.parseHTML(xmlDoc.responseText);
		
		$(this.userMessagesTextID).append(message);
		$(this.userMessagesTextID).addClass(bootstrapClass);
		
		
		$(this.userMessagesContainerID).show();
		//$(global._formID).hide();*/
	}
	
}

module.exports = frtendHelper;