//Defines globals

//DB calls
global._getMembersPATH = "json/members?page[size]=100000";
global.SECURE_MEMBER_PULL = "pull/members"

// Labels path
global.LABELS_HANDLER_PATH = "/theme/badge-app/src/php/labels.php";

// Server API Route
global._handlerPushPATH = "/theme/badge-app/src/php/push.php";
global._handlerUpdatePATH = "/theme/badge-app/src/php/update.php";
global._UPDATE_HANDLER_PATH = "/theme/badge-app/src/php/update.php";

global._SERVER_API_PUSH = "/theme/badge-app/src/php/push.php";

console.log('--');

//Reports
global.DEFAULT_TEMPLATE_URL = "/report/default-report";

//Reports Controls
global._reportControlFormID = "#js-report-controls";

// Subscription Form
global.FORM_ID = "#js-badge-form";
global.NATIONALITY_SELECT_ID = "#nationality";
global.PID_INPUT_ID = "#pid";
global.CF_INPUT_ID = "#cf";
global.CF_INPUT_GROUP_ID = "#js-cf-group";
global.PASSPORT_OPTION_ID = "#js-options-passport";

global.USER_MESSAGE_CONTAINER_ID = "#js-user-message-container";
global.USER_MESSAGE_TEXT_ID = "#js-user-message-text";

global.USER_MESSAGE_UNCHANGED_URL = "/messages/form-message-unchanged";
global.USER_MESSAGE_RENEWAL_URL = "/messages/form-message-renewal";
global.USER_MESSAGE_SUCCESS_URL = "/messages/form-message-success";
global.USER_MESSAGE_ERROR_URL = "/messages/form-message-error";
global.USER_MESSAGE_GLOBAL_ERROR_URL = "/messages/form-message-globalError";

global.INFO_CLASS = "alert-info";
global.WARNING_CLASS = "alert-warning";
global.ERROR_CLASS = "alert-danger";
global.SUCCESS_CLASS = "alert-success";


global._cssMemberCLASS = ".js-members-list";
global._defaultTEMPLATE = "default.tmpl";
global._tmplNAMES = ['default'];


// Season config
var today = new Date();

var thisYear = today.getFullYear();
var lastYear = today.getFullYear() - 1;
var nextYear = today.getFullYear() + 1;

var initThisSeason = new Date(Date.UTC(lastYear, 7, 31, 3, 0, 0, 200));
var endThisSeason = new Date(Date.UTC(thisYear, 7, 31, 0, 0, 0, 0));
var endNextSeason = new Date(Date.UTC(nextYear, 7, 31, 0, 0, 0, 0));

function getCurrentSeason () {
	var currentSeason;

	if (initThisSeason < today && today <= endThisSeason) {
		currentSeason = today.getFullYear() - 1;
	} else if (endThisSeason < today && today <= endNextSeason) {
		currentSeason = today.getFullYear();
	} else {
		currentSeason = today.getFullYear();
	}
	return currentSeason;
}

global.CURRENT_SEASON = getCurrentSeason();

//Client dialogs
global.__dialogCompleted = "#js-completed";
global.__dialogDuplicated = "#js-duplicated";
global.__dialogRenew = "#js-renew";
global.__formId = "#badge-form";

global.__jsName = ".js-name";
global.__jsSurname = ".js-surname";
global.__jsCf = ".js-cf";
global.__jscurrentSeason = ".js-currentSeason";
global.__jsStrSeason = ".js-strSeason";
global.__jsStrEndSeason = ".js-currentEndSeason";

//Reports
global.REPORTS_TABLE_ID = "#js-reports-table"
global.TABLE_CONTROLS_ID = "#js-report-table-controls"
global.TABLE_CONTROLS_FORM_ID = "#js-report-form-controls"
global.TABLE_CONTROLS_SELECTED_TXT_ID = "#js-txt-report-selected";
global.TABLE_CONTROLS_VISUALIZED_TXT_ID = "#js-txt-report-visualized";
global.TABLE_CONTROLS_BTN_SELECT_ID = "#js-select-all";
global.TABLE_CONTROLS_BTN_DESELECT_ID = "#js-deselect-all";
global.TABLE_CONTROLS_BTN_MAKE_ID = "#js-report-btn-make";

global._alrtReportsTable = "#js-alert-table"

//Season changer
global._btnAllSeason = "#js-btn-all-seasons";
global._btnCurrentSeason = "#js-btn-current-season";
global._btnSelectSeason = "#js-btn-season-";
global._dispSelectedSeason = "#js-display-selected-season";
global._chkOnlyNotReported = "#js-chk-only-not-reported";

//Tools
global._toolsFetchMailTable = "#js-fetchMails-table";
