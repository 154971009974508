'use strict';

/**
* reportsPage
*
* Page class for reports
*
*/

// Modules
var SeasonSelectComponent = require('../components/cmp.seasonSelect.js');
var MembersCollection = require('../members/members.collection.Class.js');
var ReportsTable = require('./reports.table.js');
var ReportsTableControls = require('./reports.tableControls.js');

class reportsPage {
	constructor () {
		// Public
		this._reportTableID = global.REPORTS_TABLE_ID;
		this._tableControlsID = global.TABLE_CONTROLS_ID;

		// Creates components. todo reference the page in all boject and beteter readibilty
		this._memberCollection = new MembersCollection();
		this._table = new ReportsTable(this._reportTableID);
		this._cmpSeasonSelect = new SeasonSelectComponent(this);
		this._cmpTableControls = new ReportsTableControls(this._tableControlsID, this._table);

		$('#js-btn-current-season').click();
	}

	// Thosr can go in cmp select
	// UpdaSettes the table using filter from cmp.seasonSelect
	setTable (filter) {
		var season = filter.season;
		var flagReported = filter.filterByReported;

		this._memberCollection.refreshMembers();
		var data = this._memberCollection.getMembersBySeasonAndReport(season, flagReported);

		this._table.setTableData(data);
		return null;
	}

	// Updates the table using filter from cmp.seasonSelect
	updateTable (filter) {
		var season = filter.season;
		var flagReported = filter.filterByReported;

		var data = this._memberCollection.getMembersBySeasonAndReport(season, flagReported);

		this._table.updateTableData(data);

		return null;
	}
}

module.exports = reportsPage;
