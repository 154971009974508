"use strict"; 


/**
 * membersHandler 
 *
 * Class for handling all members operation on DB
 * 
 */


// Modules
var jsonata = require("jsonata/jsonata.min.js");
const { NominatimJS } = require('nominatim-js');
var moment = require('moment');


class membersHandler {
	
		
	constructor() {
				
	}

	/**
	 * Adds one new member to DB using data coming from subscriptionForm
	 * then returns server response. Blocking.
	 *
	 * @param JSON $formData Data coming from jquery.serializeArray();
	 * @return JSON $jsonSrvResponse 
	*/
	async addMember(formData) {
		try {
			
			// Adds geoloc data
			formData = await resolveAndAddLocation( formData );
			// Push the data
			var jsonSrvResponse = await srvMemberPush( formData );
			
		} catch(errorThrown) {
			throw ("SERVER SIDE error :: " + errorThrown);
		}	
		return jsonSrvResponse;		
	}
	
	async membersUpdate(action, jsonMembers) {
		
		var jsonUpdateObject;
		var srvResponse
		
		switch (action) {
			case "report":
				
				jsonUpdateObject = _prepareUpdateJsonAction(301, _prepareUpdateJsonMembers(jsonMembers));
				srvResponse = await _srvMembersUpdate(jsonUpdateObject);
							
			break;
		}
		
		console.log(jsonUpdateObject);
		console.log(srvResponse);

		
		return null;
	}
}

function _prepareUpdateJsonMembers(jsonMembers) {
	var updateArray = [];
	
	//Fills an array with members ID
	for (var i = 0; i < jsonMembers.length; i++) {
		updateArray[i] = {
		  "pid": jsonMembers[i]["pid"]
		};
	};
	
	return updateArray;
}

function _prepareUpdateJsonAction(action, jsonMembers) {
	
	var updateData = {
		members: jsonMembers,
		action: action
	}
	
	return updateData;
}

function _srvMembersUpdate(updateData) {
	
	var srvResponse;
	
	$.ajax({
		type: "POST",
		url: global._UPDATE_HANDLER_PATH,
		data: updateData,
		async: false,
		success: (response)=>{
			srvResponse = response;
		}		
	});
	
	return srvResponse;
}

/**
 * Gets the subset of response avoiding any PHP warnings then parse to JSON
 *
 * @param array $response
 * @return JSON JSONResponse 
*/

function parseServerResponse(response) {
	
	var mySubSet = response.match('\#startResponse\#(.*)\#endResponse\#')[1];
	var JSONResponse = JSON.parse(mySubSet);
	
	return JSONResponse;
}


/**
 * Add one new member to DB using data coming from subscriptionForm
 * then returns server response. Blocking
 *
 * @param JSON $formData Data coming from jquery.serializeArray();
 * @return JSON $jsonSrvResponse 
*/

function srvMemberPush( formData ) {
	
	let srvResponse = "";
	let jsonSrvResponse = "";
	try {
		// Makes a blocking request
		$.ajax({
			type: "POST",
			async: false,
			url: global._SERVER_API_PUSH,
			data: formData,
			success: function(response) {
					srvResponse = response;
				},
			error: function(jqXHR, textStatus, errorThrown) {
					// Sets to null
					jsonSrvResponse = null;	
				}
		});
		
		// Tries to parse thw response to JSON
		try {
			jsonSrvResponse = parseServerResponse(srvResponse);
		} catch (errorThrown) {
			// If error return null
			throw ("JSON parse error :: Unexpected server response: " + errorThrown);
			jsonSrvResponse = null;	
		};
	} catch( errorThrown ) {
		throw ("srvMemberPush() ERROR :: " + errorThrown);
	}
	
	return jsonSrvResponse;
}

/**
 * Tries to geolocate member then adds lat/long to formData
 * Blocking
 *
 * @param array $formData
 * @return array $formData 
*/
async function resolveAndAddLocation( formData ) {
	
	var address = formData[7].value;
	var city = formData[8].value;
	var region = formData[9].value;
	var country = formData[11].value;
	var zip = formData[10].value;
	
	var query = `${address}, ${city}, ${zip}, ${country}`;
	
	 // Prepares data to add to $formData
    var location = {
	    "name" : "location",
	    "value" : null
    };
    
    // Prepares a JSON for bolt.cm and use it as fallback
	var boltJSONAddress = {
    	address : query,
		latitude : 0,
		longitude : 0,
		formatted_address : ""
	}
	
	// Query to Nominatim
	let results =  await NominatimJS.search({
    	q: query
  	});
  	
  	// If $results not empty sets values to $boltJSONAddress
  	if (results.length > 0) {
		boltJSONAddress.latitude = results[0].lat;
		boltJSONAddress.longitude = results[0].lon;
		boltJSONAddress.formatted_address = results[0].display_name;
	}
	
	// Parse to JSON to match bolt.cm field requirements	
	location.value = JSON.stringify(boltJSONAddress);  
        
	// Adds $location to $formData
	formData.push(location);
	  		
    return formData;
}

module.exports = membersHandler;