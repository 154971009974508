'use strict';

/**
* seasonSelect
*
* Component class. Season select component. Generates a filter usinf a form
*
* @param Objcet $referencePage Page where component is created.
*
*/

// Modules
var BoltLabels = require('../utils/boltLabels.js');
var $;
$ = require('jquery');

var bltLbl = new BoltLabels();

class seasonSelect {
	constructor (referencePage) {
		this._formId = 'js-season-select-form';
		this._helperTextId = '#js-display-selected-season';
		this._buttonClass = '.js-btn-season-action';

		this._formObj = $(this._formId);
		this._bindButtonActions(referencePage);
		this._referencePageObject = referencePage;
	}

	// Binds action to form buttons
	_bindButtonActions (referencePage) {

		// $('#js-chk-only-not-reported').on('change', {refenceComponent: this}, clickHandler);
		$(this._buttonClass).on('click', {refenceComponent: this}, clickHandler);

		// Buttons click handler function
		function clickHandler (event) {
			var cmp = event.data.refenceComponent;

			// Prepares a filter object
			var filter = {
				filterByReported: cmp._getFilterByReportedValue(cmp._getFormValues()),
				season: $(event.target).data('season')
			};

			console.log(filter);
			console.log(event.target);

			// Performs action using the page reference object;
			referencePage.setTable(filter);

			// Updates helper text.
			cmp._updateHelperText($(event.target).data('season'));
		};
	}

	// Checks if property filterByReport is "on", then returns true/false
	_getFilterByReportedValue (jsonOptions) {
		if (jsonOptions.filterByReport == 'on') {
			return false;
		} else {
			return true;
		}
	}

	// Gets form values in json format.
	_getFormValues () {
		var jsonOptions = {};
		var formArray = this._formObj.serializeArray();

		// Convert to JSON <3
		$(formArray).each(function (index, obj) {
			jsonOptions[obj.name] = obj.value;
		});

		return jsonOptions;
	}

	// Update helper text
	_updateHelperText (text) {

		// If text is String
		if (typeof text === 'string' || text instanceof String) {

			// If "all" or "current" use boltLabels to translate
			switch (text) {
				case "all":
				$(this._helperTextId).text(bltLbl.l("cmp.season.select.all"));
				break;
				case "current":
				$(this._helperTextId).text(bltLbl.l("cmp.season.select.current"));
				break;
				default:
				$(this._helperTextId).text(bltLbl.l(text));
				break;
			}

		} else {
			$(this._helperTextId).text(text);
		}
	}
}

module.exports = seasonSelect;
