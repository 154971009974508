'use strict';

/**
 * tableControls
 *
 *
 */

// Modules
var moment = require('moment');
var reportsMakeModule = require('./reports.make.js');
var bckendHelperModule = require('../ui/ui.bckendHelper.js');
var membersHandlerModule = require('../members/members.handler.js');
var boltLabels = require('../utils/boltLabels.js');

var bltLbl = new boltLabels ();
var membersHandler = new membersHandlerModule();

class tableControls {

	constructor (controlsComponentID, referenceTable) {

		this._controlsComponentID = controlsComponentID;

		// Forms
		this._formObj = $(global.TABLE_CONTROLS_FORM_ID);

		// Texts
		this._textVisualized = global.TABLE_CONTROLS_VISUALIZED_TXT_ID;
		this._textSelected = global.TABLE_CONTROLS_SELECTED_TXT_ID;

		// Buttons
		this._btnSelectAll = global.TABLE_CONTROLS_BTN_SELECT_ID;
		this._btnDeselectAll = global.TABLE_CONTROLS_BTN_DESELECT_ID;
		this._btnMake = global.TABLE_CONTROLS_BTN_MAKE_ID;

		this._table = referenceTable;

		// Creates Component
		this._cmp = $(this._controlsComponentID);

		// Creates reportMaker
		this._reportMaker = new reportsMakeModule();

		// Creates ui.helper
		this._uiHelper = new bckendHelperModule();

		// Binds this cmp whit its reference table
		this._table.setTableControlsComponent(this);

		// Binds controls actions.
		this._bindControlsAction();
	}

	updateSelected (data) {
		$(this._textSelected).text(data);
	}

	updateVisualized (data) {
		$(this._textVisualized).text(data);
	}

	_bindControlsAction () {
		// Select ALL.
		$(this._btnSelectAll).click(() => {
			this._table._tableObject.selectRow(true);
		});

		// Deselect ALL.
		$(this._btnDeselectAll).click(() => {
			this._table._tableObject.deselectRow();
		});

		// Button make report.
		$(this._btnMake).click(() => {
			this._reportAction()
		});
	}

	// Creates a report and make an action on it by given action from form
	_reportAction () {
		var reportOptions = this._getFormValues();
		var selectedMembers = this._table._tableObject.getSelectedData();
		var pdfDocument = this._makeReport(reportOptions, selectedMembers);
		var filename = 'Verbale - ' + moment().format('DD-MM-YYYY hh-mm');

		if (pdfDocument !== undefined) {
			switch (reportOptions.action) {
				case 'download':
					pdfDocument.download(filename);
					break;
			}
		}

		membersHandler.membersUpdate('report', selectedMembers);
		// Refresh table
	}

	// Make report
	_makeReport (reportOptions, selectedMembers) {
		var pdfDocument;

		if (selectedMembers.length != 0 || selectedMembers == undefined) {
			pdfDocument = this._reportMaker.make(reportOptions, selectedMembers);
		} else {
			this._uiHelper.ntfy(bltLbl.l('cmp.dashboard.reports.noselection'), 'danger')
		}

		return pdfDocument;
	}

	// Gets form values in json format.
	_getFormValues () {
		var jsonOptions = {};
		var formArray = this._formObj.serializeArray();

		// Convert to JSON <3
		$(formArray).each(function (index, obj) {
			jsonOptions[obj.name] = obj.value;
		});

		return jsonOptions;
	}
}

module.exports = tableControls;
