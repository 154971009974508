 
//Modules
const tmplUtils = require('../templateEngine/templateEngineUtils.js');

/**
 * Makes a Template for PDF with given template and list of members.
 *
 * @param array $members Members fetched on table
 * @param string $templateName Wich template has to be used
 * @return DOM $html HTML Template 
*/
exports.makeTemplate = function (templateName, members) {
	HTMLTemplate = fetchTemplate(templateName);
	HTMLTemplate = tmplUtils.injectList(HTMLTemplate, members);
		
	return HTMLTemplate	
}


/**
 * Fetches template in async mode
 *
 * @param string $templateURL URL of the template
 * @param JSON $data $_POST data to send to Twig Engine
 *
 * @return string $xml text 
*/
exports.fetchTemplate = function(templateURL, JSONData) {
	
	var xml = "";
		
	$.ajax({
	    url : templateURL,
	    method : "post",
		data:  JSONData,
	    async: false,
	    //dataType: "xml",
	    complete : function(data) {
		   
	       xml = data;
	       
	    },
	    error: function() {
	       console.log("Template fetching error. Using fallback");
	       xml = "<message><h5>Server side error</h5>Sorry, something went wrong... Cannot load message template.</message>"
	    }
 	});
	
 	return xml;
};

exports.fetchReportTemplate = function(templateName, data) {
	
	var xml = "";	
	var url = "/report/" + templateName;
		
	$.ajax({
	    url : url,
	    method : "post",
		data:  data,
	    async: false,
	    //dataType: "xml",
	    complete : function(data) {
		   
	       xml = data;
	       
	    },
	    error: function() {
	       console.log("Template fetching error. Using fallback");
	       xml = "<message><h5>Server side error</h5>Sorry, something went wrong... Cannot load message template.</message>"
	    }
 	});
	
 	return xml;
}
