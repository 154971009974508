'use strict';

/**
* reportsTable
*
* Class for report table
*
*/

// Modules
var Tabulator = require('tabulator-tables');

class reportsTable {
	constructor (tableID) {
		// Class globals
		this._tableObject = {};
		this._tableControlsComponent = {};

		// Table options and callbacks
		this._tableOptions = {
			locale: true,
			selectable: true,
			selectableRangeMode: 'click',
			selectablePersistence: true,
			initialSort: [
				{column: 'badgenumber', dir: 'asc'}
			],
			reactiveData: true,
			layout: 'fitData',
			columns: [
				{title: 'ID', field: 'slug', visible: false},
				{title: 'N.Tessera', field: 'badgenumber', sorter: 'alphanum', headerFilter: 'input'},
				{title: 'Nome', field: 'name', sorter: 'string', headerFilter: 'input'},
				{title: 'Cognome', field: 'surname', sorter: 'string', headerFilter: 'input'},
				{
					title: 'Data Iscrizione',
					field: 'lastsubscription',
					sorter: 'alphanum',
					sorterParams: {
						format: 'DD/MM/YYYY',
						alignEmptyValues: 'top'
					},
					formatter: 'datetime',
					formatterParams: {
						inputformat: 'YY-MM-DD',
						outputFormat: 'DD/MM/YYYY',
						invalidPlaceholder: '(non ancora verbalizzato)'
					}
				},
				{title: 'Verbale stagione<br/>corrente', field: `seasons.${global.CURRENT_SEASON}.report`, formatter: 'tickCross'},
				{
					title: 'Ultimo verbale',
					field: 'lastreport',
					sorter: 'alphanum',
					formatter: 'datetime',
					formatterParams: {
						inputFormat: 'YY-MM-DD hh:mm:ss',
						outputFormat: 'DD/MM/YY hh:mm',
						invalidPlaceholder: '(non ancora verbalizzato)'
					}
				}
			],
			rowSelectionChanged: (data, rows) => {
				// It's automaticlly invoked at table creation!
				this._onRowSelectionChange(data, rows, this._tableControlsComponent);
			},
			renderComplete: (data, rows) => {
				// It's automaticlly invoked at table creation!
				this._onTableRedraw(this._tableControlsComponent);
			}
		};

		// Creates a table
		this._createTable(tableID);
	}

	updateTableData (jsonObject) {
		var data = this._castAsArray(jsonObject);
		this._tableObject.updateData(data);

		return null;
	}

	setTableData (jsonObject) {
		var data = this._castAsArray(jsonObject);
		console.log(data);
		this._tableObject.setData(data);

		return null;
	}

	// Links this table to its TableControls component
	setTableControlsComponent (cmpTableControls) {
		this._tableControlsComponet = cmpTableControls;
		return null;
	}

	// Updates selected rows counter on selection change
	_onRowSelectionChange (data, rows, cmp) {
		// Avoids execution when cmp is not yet defined
		if (cmp.updateSelected) {
			cmp.updateSelected(data.length);
		}
	}

	// Updates visualized rows counter on table redraw
	_onTableRedraw (cmp) {
		// Avoids execution when cmp is not yet defined
		if (cmp.updateVisualized) {
			var rowCount = this._tableObject.rowManager.displayRowsCount;
			cmp.updateVisualized(rowCount);
		}
	}

	// Transorms jsonObject to Array for Tabulators.js.
	_castAsArray (jsonObject) {
		var resultArray = [];

		$.each(jsonObject, function (key, value) {
			resultArray.push(value);
		});

		return resultArray;
	}

	// Creates the table using given DOMObjectID
	_createTable (tableID) {
		this._tableObject = new Tabulator(tableID, this._tableOptions);
	}
}

module.exports = reportsTable;
