/**
* subscriptionForm
*
* Class for creating a subscription form
*
* @constructor {string} formID The form element ID
*/

// Modules
var parsley = require('parsleyjs');
var jsonata = require('jsonata/jsonata.min.js');
var membersHandler = require('../members/members.handler.js');
var frontendHelper = require('../ui/ui.frtendHelper.js');

var formObj;
var mbrHandler;
var uiHelper;

class subscriptionForm {

	constructor (formID) {
		formObj = $(formID);
		mbrHandler = new membersHandler();
		uiHelper = new frontendHelper();

		// Binds actions to the form.
		this.bindNationalityChanger();
		this.bindValidation();
		$(global.NATIONALITY_SELECT_ID).change();
	}

	// Binds onchange action on nationality selection.
	bindNationalityChanger () {
		$(global.NATIONALITY_SELECT_ID).change(function () {
			// If NOT italians
			if (this.value !== 'IT') {
				// Hides CF input group
				$(global.CF_INPUT_GROUP_ID).hide();

				// Adds passport option
				$(global.PASSPORT_OPTION_ID).removeAttr('hidden');

				// Sets CF as "non-inserted"
				updateCf('XXXXXXXXXXXXXXXX');
			} else {
				//	$(global.PID_INPUT_ID).unbind();

				// Set CF to ""
				updateCf('');
				// Hide passport option
				$(global.PASSPORT_OPTION_ID).attr('hidden', '');
				// Shows CF input
				$(global.CF_INPUT_GROUP_ID).show();
			}
		});

		// Cannot update value inside .change()
		function updateCf (value) {
			$(global.CF_INPUT_ID).val(value);
		}
	};

	// Binds validation actions.
	bindValidation () {
		$(formObj).parsley().on('field:validated', function () {
			var flag;
			flag = $('.parsley-error').length === 0;
		}).on('form:submit', function () {

			// Collects data
			var formData = $(formObj).serializeArray();

			$('#js-report-spinner').show();

			// Sends data to membersHandler then waits the response.
			// Uses an async thread
			(async () => {
				try {
					let srvResponse = await mbrHandler.addMember(formData);
					var memberData = srvResponse.dataInserted;

					// Shows message to user based on response status code using frontendUIHelper.
					switch (jsonata('status').evaluate(srvResponse)) {
						// ADD - 101
						case 101:
							$(formObj).fadeOut('slow', function (){
								uiHelper.showBootstrapMessage(global.USER_MESSAGE_SUCCESS_URL, global.SUCCESS_CLASS, memberData);
							});
							break;

						// RENEW - 102
						case 102:
							$(formObj).fadeOut('slow', function (){
								uiHelper.showBootstrapMessage(global.USER_MESSAGE_RENEWAL_URL, global.SUCCESS_CLASS, memberData);
							});
							break;

						// UNCHANGED - 103
						case 103:
							$(formObj).fadeOut('slow', function (){
								uiHelper.showBootstrapMessage(global.USER_MESSAGE_UNCHANGED_URL, global.INFO_CLASS, memberData);
							});
							break;

						// Status code not handled
						default:
							$(formObj).fadeOut('slow', function () {
								uiHelper.showBootstrapMessage(global.USER_MESSAGE_ERROR_URL, global.ERROR_CLASS, memberData);
							});
							break;
					}
				} catch (error) {
					console.log(error);
					// Shows an error
					$(formObj).fadeOut('slow', function () {
						uiHelper.showBootstrapMessage(global.USER_MESSAGE_ERROR_URL, global.ERROR_CLASS, memberData);
					});
				}
			})();

			return false;
		});
	}
}

module.exports = subscriptionForm;
