'use strict';

/**
* membersCollection
*
* Class for collecting members from DB
*
*/

// Modules
var jsonata = require('jsonata/jsonata.min.js');
var moment = require('moment');

class membersCollection {
	constructor () {
		this._filteredMembersCollection = '';
		this._membersCollection = this._fetchMembers();
	}

	/* This function will return raw members data. */
	getRawMembers () {
		return this._membersCollection;
	};

	/* This function will return filtered members data */
	getMembers () {
		return this._filteredMembersCollection;
	}

	/* This function resets internal state of filtered members */
	resetFilters () {
		this._filteredMembersCollection = this._membersCollection;
		return null;
	}

	// Reload members
	refreshMembers () {
		this._membersCollection = this._fetchMembers();
		return null;
	}

	// Get members filtering with a query
	getMembersByQuery (query, flagStore) {
		var jsonMembers;

		return jsonMembers;
	}

	getMembersBySeason (season, flagStore) {
		var jsonMembers;

		jsonMembers = this._membersCollection;
		jsonMembers = this._filterMembersBySeason(jsonMembers, season);

		this._storeFilteredData(flagStore, jsonMembers);

		return jsonMembers;
	}

	getMembersBySeasonAndReport (season, filterByReport, flagStore) {
		var jsonMembers;

		jsonMembers = this._membersCollection;

		jsonMembers = this._filterMembersBySeason(jsonMembers, season);

		jsonMembers = this._filterMembersByReported(jsonMembers, filterByReport);

		console.log(jsonMembers);

		this._storeFilteredData(flagStore, jsonMembers);

		return jsonMembers;
	}

	// Stores the filtered data to internal filtered data
	_storeFilteredData (flag, jsonMembers) {
		if (flag === true) {
			this._filteredMembersCollection = jsonMembers;
		}

		return null;
	}

	// Gets members from DB
	_fetchMembers () {
		var jsonMembers;

		/* Collects members */
		$.ajax({
			url: global.SECURE_MEMBER_PULL,
			async: false,
			dataType: 'json',
			complete: function (response) {
				jsonMembers = response.responseJSON;
			}
		});

		return jsonMembers;
	}

	// ** Filters functions
	_filterMembersBySeason (jsonMembers, season) {
		var query;

		// Handles some litterals
		switch (season) {
			case 'all':
				query = `$.*{$string(id):$[seasons.*]}`;
				break;
			case 'current':
				query = `$.*{$string(id):$[seasons."${global.CURRENT_SEASON}"]}`;
				break;
			default:
				query = `$.*{$string(id):$[seasons."${season}"]}`;
				break;
		}

		jsonMembers = this._performQuery(jsonMembers, query);

		return jsonMembers;
	}

	_filterMembersByReported (jsonMembers, flagReported) {
		var query;

		if (!flagReported) {
			query = `$.*{$string(id):$[seasons.*.report=false]}`;
		} else {
			// TODO: make a better query string with a wildcard on report field only.
			query = `$.*{$string(id):$[seasons.*.*]}`;
		}

		jsonMembers = this._performQuery(jsonMembers, query);

		return jsonMembers;
	}

	// Performs jSonata query
	_performQuery (jsonMembers, query) {
		try {
			jsonMembers = jsonata(query).evaluate(jsonMembers);
		} catch (e) {
			console.log('Query failed: ' + e.message);
		};

		return jsonMembers;
	}

	/* Filters members by (x) weeks before today */
	filterByLastWeeks (number, data, store) {
		var result;

		if (number === undefined) {
			/* Fallbacl to week -1 */
			number = 1;
		};

		if (data === undefined) {
			/* Fallback to filtered internal state */
			data = this.filteredData;
		};

		if (store === undefined) {
			/* Fallback to false and do not store on filter internal state */
			store = false;
		}

		/* sets the time travel */
		var time = moment().subtract(number, 'weeks').format('YYYY-MM-DD');

		/* creates jsonata query */
		var query = "*[lastsubscription > '" + time + "']";

		console.log(query);

		try {
			result = jsonata(query).evaluate(data);
			if (result === undefined) {
				const errorMessage = {message: 'Result is empty'};
				throw errorMessage;
			}
		} catch (error) {
			console.log('Query failed: ' + error.message);
		};

		if (store) {
			/* Stores to internal filters */
			this.filteredData = result;
		};

		return result;
	}

	/* Gets average age of members */
	getAverageAge () {
		var result = 0;
		var members;

		/* creates jsonata query */
		var query = '*.age';

		try {
			members = jsonata(query).evaluate(this.filteredData);
		} catch (e) {
			console.log('Query failed: ' + e.message);
		};

		var sum = 0;
		var total = 0;

		for (var i = 0; i < members.length; i++) {
			if (members[i] !== 0 || members[i] !== undefined) {
				sum = sum + Number(members[i]);
				total++;
			}
		}

		result = sum / total;
		result = result.toPrecision(3);

		return result;
	}
}

module.exports = membersCollection;
