"use strict";

/**
 * reportsMaker
 *
 * 
 */

// todo Create a superior class


// Modules
var moment = require('moment');
var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var htmlToPdfMake = require("html-to-pdfmake");
var tmplEngine = require('../templateEngine/templateEngine.js');

var boltLabels = require('../utils/boltLabels.js');
var bltLbl = new boltLabels();


class reportsMaker {
		
	constructor() {
		
	}
	
	// Generates report PDF and return it
	make(reportOptions, jsonMembers) {
		
		var pdf = this._createPDF(reportOptions, jsonMembers);		
		return pdf;	
		
	}
	
	_createPDF(reportOptions, jsonMembers) {
		var pdf;
		var docDefinition;
		
		// Prepares the report date using given options
		reportOptions.reportDate = this._prepareDatesForReport(reportOptions);		
				
		// String jsonMembers
		var memberStr = JSON.stringify(jsonMembers);
		
		// Prepares twig data to inject	
		var twigData = {
    		str: memberStr,
			date: reportOptions.reportDate,
			startHour: reportOptions.reportStartTime,
			endHour: reportOptions.reportEndTime,
			place: reportOptions.reportPlace
    	};
    	
    	// Fetcht template and create doc definition
    	docDefinition = this._createDocDefinition(reportOptions.template, twigData);
    	
    	// Create one
    	pdf = pdfMake.createPdf(docDefinition);
    			
		return pdf;
	}
	
	_createDocDefinition(template, data) {
		
		var xml;
		var html;
		
		// Fetches template
		xml = tmplEngine.fetchReportTemplate(template, data);
		
		// Parses to Pdf
		html = htmlToPdfMake(xml.responseText);
		
		// Creates a doc definition.
		var docDefinition = {
			content: [
				html
			]
		};

		
		return docDefinition;
	}
	
	_prepareDatesForReport(reportOptions) {
		var date = "";
		var today = moment();
		var yesterday = moment().subtract(1,"day");
		var customDate = moment(reportOptions.reportDateCustom, "YYYY-MM-DD");
		
		switch(reportOptions.reportDate) {
			case "today":
				date = today.format("YYYY-MM-DD"); 
			break;
			case "yesterday":
				date = yesterday.format("YYYY-MM-DD"); 
			break;
			case "custom":
				date = customDate.format("YYYY-MM-DD");
			break;
		};
				
		return date;
	}
}

module.exports = reportsMaker;
