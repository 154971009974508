/**
 * Injects members list in HTML report template
 *
 * @param DOM $html html to be precessed
 * @param array $members List of members to be added
 * @return DOM HTML Template 
*/

exports.injectList = function (html, members) {
		
	for (member of members) {
		li = $.parseHTML(
			"<li>" + 
				member.name + " " + member.surname + 
				", nato a " + member.cityborn + 
				"<span>" +
					member.cf +
				"</span>" +
			"</li>" 
		);
		$(_cssMemberCLASS,html).append(li);	

	};
	
	return html
	
}